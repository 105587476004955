import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwaXTJ7AXEbJpbsZBlasP6eIyVHYtT9Yg",
  authDomain: "twitter-clone-596ea.firebaseapp.com",
  databaseURL: "https://twitter-clone-596ea.firebaseio.com",
  projectId: "twitter-clone-596ea",
  storageBucket: "twitter-clone-596ea.appspot.com",
  messagingSenderId: "502496473753",
  appId: "1:502496473753:web:c184350cfe05e6c378fe08",
  measurementId: "G-QDDCFYMS6M",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export default db;